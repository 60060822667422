import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  learnerData: {
    subcategory: "",
    genre: "",
    event: "",
    location: "",
    time: "",
    frequency: "",
    mode: "",
    goal: "",
    exGender: "",
    expert: "",
    experts: ""
  },
  llogin: {
    isLoggedIn: false,
    loginAuth: {},
    profile: {}
  },
  isEvent: false,
  isAllCat: false,
};

const learnerSlice = createSlice({
  name: "learner",
  initialState,
  reducers: {
    setLearnerData: (state, action) => {
      state.learnerData = action.payload;
    },
    setEvent: (state, action) => {
      state.isEvent = action.payload;
    },
    setAllCat: (state, action) => {
      state.isAllCat = action.payload;
    },
    setLearnerLogin: (state, action) => {
      state.llogin = action.payload;
    }
    }
  },
);

export const { setLearnerData, setLearnerLogin, setEvent, setAllCat } = learnerSlice.actions;

export default learnerSlice.reducer;
