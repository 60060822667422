import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoader: false,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setIsLoader: (state, action) => {
      state.isLoader = action.payload;
    },
  },
});

export const { setIsLoader } = siteSlice.actions;

export default siteSlice.reducer;
